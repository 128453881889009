import React from "react";
import './Top.css'
import TopUser from "./TopUser";
import TopContent from "./TopContent";
 
const Top =()=>{
    return(
        <div>
             <TopUser/>
             <TopContent/> 
        </div>
    )
}

export default Top