import React, { useEffect, useState, } from "react";
import TopUser from "../Top Section/TopUser";
import QR from "../qr/QR";

const Driver =()=>{
    return(
        <div>
           <TopUser/>  
           <QR/>
      </div>
    )
    
}
export default Driver