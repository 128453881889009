import React, { useEffect, useState } from "react";
import TopUser from "../Top Section/TopUser";
import Rooms from "../Room/Rooms";
const room =()=>{
    return(
      <div>
            <TopUser/>
            <Rooms/>
        </div>
    )
    
}
export default room